<template>
  <div class="page-user-mine">
    <!-- 头部渐变背景 -->
    <div class="my-head">
      <div class="my-one">
        <div class="my-info">
          <div class="my-pic"></div>
          <div class="my-des">
            <div class="my-name">林秋慢</div>
            <div class="my-label">大三班 / 教师、保育员、园长</div>
          </div>
        </div>
        <div class="my-code"></div>
      </div>
      <div class="my-two">
        <div class="my-card">
          <div class="item">
            <span>12</span>
            <div>本月作息</div>
          </div>
          <div class="item">
            <span>18</span>
            <div>本月消毒</div>
          </div>
          <div class="item">
            <span>8</span>
            <div>本月食谱</div>
          </div>
          <div class="item">
            <span>16</span>
            <div>本月亲子</div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-page">
      <div class="my-card">
        <van-cell title="个人资料" icon="description" is-link @click="click" />
        <van-cell title="提醒设置" icon="bullhorn-o" is-link @click="click" />
        <van-cell title="公示设置" icon="eye-o" is-link @click="click" />
        <van-cell title="系统版本" icon="setting-o" is-link @click="click" />
      </div>
      <!-- <van-button type="primary" block color="#ffe500" @click="logout"
        >退出登录</van-button
      > -->
    </div>
    <!-- 菜单栏 -->
    <NavBar v-bind:active="3" />
  </div>
</template>

<script>
import defaultImg from '@/assets/icon/icon-my.png'
import NavBar from '@/components/NavBar'
import { Cell, CellGroup, Icon, Button } from 'vant'
export default {
  components: {
    NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon,
    [Button.name]: Button
  },
  data () {
    return {
      defaultImg: defaultImg
    }
  },
  mounted () {
    // this.getUser()
  },
  methods: {
    click () {
      this.$toast('功能正在开发中...')
    },
    logout () {
      window.localStorage.clear()
      this.$router.replace('ChoseRole')
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
